<template>
  <div>
    <div class="location-list-filters">
      <div class="location-list-filters__section">
        <div class="location-list-filters__section__label">
          {{ $t('locations.list.filters.status') }}
        </div>
        <div class="location-list-filters__section__radios">
          <ui-radio
            class="location-list-filters__section__radios__radio"
            id="status-all"
            value="ENABLED|DISABLED"
            name="status"
            :reverse="true"
            v-model="filters.status"
          >
            {{ $t('locations.list.filters.all') }}
          </ui-radio>
          <ui-radio
            class="location-list-filters__section__radios__radio"
            id="status-enabled"
            value="ENABLED"
            name="status"
            :reverse="true"
            v-model="filters.status"
          >
            {{ $t('locations.list.filters.enabled') }}
          </ui-radio>
          <ui-radio
            class="location-list-filters__section__radios__radio"
            id="status-disabled"
            value="DISABLED"
            name="status"
            :reverse="true"
            v-model="filters.status"
          >
            {{ $t('locations.list.filters.disabled') }}
          </ui-radio>
        </div>
      </div>

      <div class="location-list-filters__section">
        <div class="location-list-filters__section__head">
          <div class="location-list-filters__section__label">
            {{ $t('locations.list.filters.group.label') }}
          </div>
          <div class="location-list-filters__section__head__reset" @click="resetGroup">
            {{ $t('locations.list.filters.group.reset') }}
          </div>
        </div>
        <ui-dropdown
          id="modal-simple-field-dropdown"
          v-model="filters.group"
          label="name"
          track-by="value"
          :options="clientListGroups"
          :placeholder="$t('locations.list.filters.group.placeholder')"
          :show-label="false"
        />
      </div>

      <div class="location-list-filters__section">
        <div class="location-list-filters__section__label">
          {{ $t('locations.list.filters.associationGMB') }}
        </div>
        <div class="location-list-filters__section__radios">
          <ui-radio
            class="location-list-filters__section__radios__radio"
            id="AssociationGMB-all"
            value="ENABLED|DISABLED"
            name="AssociationGMB"
            :reverse="true"
            v-model="filters.associationGMB"
          >
            {{ $t('locations.list.filters.all') }}
          </ui-radio>
          <ui-radio
            class="location-list-filters__section__radios__radio"
            id="AssociationGMB-enabled"
            value="ENABLED"
            name="AssociationGMB"
            :reverse="true"
            v-model="filters.associationGMB"
          >
            {{ $t('locations.list.filters.enabled') }}
          </ui-radio>
          <ui-radio
            class="location-list-filters__section__radios__radio"
            id="AssociationGMB-disabled"
            value="DISABLED"
            name="AssociationGMB"
            :reverse="true"
            v-model="filters.associationGMB"
          >
            {{ $t('locations.list.filters.disabled') }}
          </ui-radio>
        </div>
      </div>

      <div class="location-list-filters__section">
        <div class="location-list-filters__section__label">
          {{ $t('locations.list.filters.eventCustom') }}
        </div>
        <div class="location-list-filters__section__radios">
          <ui-radio
            class="location-list-filters__section__radios__radio"
            id="eventCustom-all"
            value="ENABLED|DISABLED"
            name="eventCustom"
            :reverse="true"
            v-model="filters.eventCustom"
          >
            {{ $t('locations.list.filters.all') }}
          </ui-radio>
          <ui-radio
            class="location-list-filters__section__radios__radio"
            id="eventCustom-enabled"
            value="ENABLED"
            name="eventCustom"
            :reverse="true"
            v-model="filters.eventCustom"
          >
            {{ $t('locations.list.filters.enabled') }}
          </ui-radio>
        </div>
      </div>

      <div class="location-list-filters__section">
        <ui-checkbox
          id="without-country"
          class="location-list-filters__section__checkbox"
          :reverse="true"
          align="space-between"
          v-model="filters.withoutCountry"
        >
          {{ $t('locations.list.filters.withoutCountry') }}
        </ui-checkbox>
      </div>
      <div class="location-list-filters__section">
        <ui-checkbox
          id="without-latitude"
          class="location-list-filters__section__checkbox"
          :reverse="true"
          align="space-between"
          v-model="filters.withoutLatitude"
        >
          {{ $t('locations.list.filters.withoutLatitude') }}
        </ui-checkbox>
      </div>
      <div class="location-list-filters__section__buttons">
        <div class="location-list-filters__section__buttons__reset">
          <ui-button button="secondary" icon="reset" :label="$t('locations.list.filters.reset')" @click="reset" />
        </div>
        <div class="location-list-filters__section__buttons__close">
          <ui-button button="primary" v-close-popover icon="close" :label="$t('locations.list.filters.close')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UiRadio from '@/components/UI/Radio.vue'
import UiCheckbox from '@/components/UI/Checkbox.vue'
import UiDropdown from '@/components/UI/Dropdown.vue'
import UiButton from '@/components/UI/Button.vue'

const getStringifiedStatus = value => {
  switch (value) {
    case 'ENABLED':
      return 'status:1'
    case 'DISABLED':
      return 'status!1'
    case 'ENABLED|DISABLED':
    default:
      return ''
  }
}
const getStringifiedEventCustom = value => {
  switch (value) {
    case 'ENABLED':
      return 'eventCustom:1'
    case 'ENABLED|DISABLED':
    default:
      return ''
  }
}
const getStringifiedAssociationGMB = value => {
  switch (value) {
    case 'ENABLED':
      return 'gpbStatus:1'
    case 'DISABLED':
      return 'gpbStatus!1'
    case 'ENABLED|DISABLED':
    default:
      return ''
  }
}

const getStringifiedGroup = group => (group ? `group:${group.value}` : '')
const getStringifiedWithoutCountry = value => (value === true ? 'countryCode:null' : '')
const getStringifiedwithoutLatitude = value => (value === true ? 'latitude:null' : '')

const defaultFilters = {
  status: 'ENABLED',
  eventCustom: 'ENABLED|DISABLED',
  withoutCountry: false,
  withoutLatitude: false,
  associationGMB: 'ENABLED|DISABLED',
  group: null,
}

export default {
  name: 'LocationListFilter',
  components: {
    UiRadio,
    UiCheckbox,
    UiButton,
    UiDropdown,
  },
  props: {
    frontofficesFilter: {
      type: Boolean,
      required: false,
      default: false,
    },
    clientListGroups: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      filters: {
        ...defaultFilters,
      },
    }
  },
  computed: {
    stringifiedSearch() {
      return [
        getStringifiedStatus(this.filters.status),
        getStringifiedEventCustom(this.filters.eventCustom),
        getStringifiedWithoutCountry(this.filters.withoutCountry),
        getStringifiedwithoutLatitude(this.filters.withoutLatitude),
        getStringifiedAssociationGMB(this.filters.associationGMB),
        getStringifiedGroup(this.filters.group),
      ]
        .filter(filter => filter && filter.length > 0)
        .join(',')
    },
  },
  watch: {
    filters: {
      immediate: true,
      deep: true,
      handler: 'onUpdateFilters',
    },
  },
  methods: {
    resetGroup() {
      this.filters.group = null
    },
    onUpdateFilters() {
      this.$emit('onUpdateFilters', this.stringifiedSearch)
    },
    reset() {
      this.filters = { ...defaultFilters }
    },
  },
}
</script>

<style lang="scss" scoped>
.location-list-filters {
  margin: 0 auto;

  &__section {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;

    @media (min-width: $screen-sm) {
      margin-bottom: $gutter-tablet;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &__head {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &__reset {
        cursor: pointer;
      }
    }

    &__label {
      margin-bottom: 0.5rem;
    }

    &__label,
    &__checkbox {
      font-weight: 600;
    }

    &__radios {
      display: flex;
      justify-content: space-between;
      &__radio {
        margin: 0 0.5rem;

        &:first-child {
          margin: 0;
          margin-right: 0.5rem;
        }
        &:last-child {
          margin: 0;
          margin-left: 0.5rem;
        }
      }
    }

    &__buttons {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0;
    }
  }
}
</style>
